import { Routings } from '@/common/routings';
import { getPartneridForSessionStorage } from '@/common/utilities';
import Button from '@/components/atoms/Button';
import ErrorMsg from '@/components/atoms/ErrorMessage';
import RadioButton from '@/components/atoms/RadioButton';
import Select from '@/components/atoms/Select';
import SubmitButton from '@/components/atoms/SubmitButton';
import { TermsInArea } from '@/components/atoms/Terms';
import { Breakpoints, FloorPlanArray, UNSELECTED } from '@/constants/constants';
import { AssessmentFormValue } from '@/models/request/assessment';
import * as assessment from '@/state/modules/assessment';
import theme from '@/styles/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash';
import {
  Assessment,
  AssessmentAddress,
  AssessmentMansion,
  AssessmentRoom,
  BathroomType,
  DoormanMansion,
  DoormanUnits,
  RentManagementFeeType,
  RentManagementStatus,
  RentType,
  TenancyStatus,
} from 'openapi/kusabi-frontgw-spec';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { FormState, schema } from './state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GuideTextWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 40px 0px 20px 0px;
`;

const GuideText = styled.label`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_20_32};
  }
`;

const FormContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #333333;
  margin-bottom: 0px;
`;

const RoomNumberArea = styled.div`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    margin-bottom: 40px;
  }
`;

const Row = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;
  height: 90px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 0px;
    }
  }
`;

const RentPriceRow = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;
  height: 65px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 0px;
    }
  }
`;

const Th = styled.th`
  width: 310px;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 50px;
    vertical-align: baseline;
    padding: 0 0 20px 0;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
    margin-top: 30px;
  }
`;

const InnerTh = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  label {
    text-align: left;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-left: 0px;
  }
`;

const ThLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_20_32};
  }
`;

const Required = styled.span`
  display: inline-block;
  height: 20px;
  background-color: #fff;
  border: 1px solid #d1a827;
  border-radius: 4px;
  font: ${theme.fonts.bold_10_20};
  color: #d1a827;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
`;

const Td = styled.td`
  padding-left: 28px;
  padding-top: 30px;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: max-content;
    padding: 20px 0 0 0;
  }
`;

const RentPriceTd = styled.td`
  padding-left: 28px;
  padding-top: 2px;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0 0 0;
  }
`;

const InnerTd = styled.div`
  width: 436px;
  display: flex;
  flex-direction: row;
  font: ${theme.fonts.normal_16_32};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const TdLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  white-space: nowrap;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -17px;
  label {
    padding-left: 10px;
    font: ${theme.fonts.normal_16_32};
    color: #333333;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-top: 0px;
    width: 100%;
  }
`;

const TextFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  label {
    white-space: nowrap;
    font: ${theme.fonts.normal_16_32};
    color: #333333;
    padding-left: 10px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const RadioButtonDetailArea = styled.div`
  display: flex;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
  }
  radioButton {
    width: 100%;
  }
`;

const TextField = styled.input`
  width: 190px;
  height: 30px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const RentManagementFeeTextField = styled.input`
  width: 190px;
  height: 30px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AssessmentButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f 0% 0% no-repeat padding-box;
  font: ${theme.fonts.bold_22_33};
  border-radius: 4px;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 190px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const RentManagementFeeTypeSelect = styled(Select)`
  width: 64px;
  height: 30px;
  display: inline-flex;
`;

const SelectOption = styled.option`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;

const ErrorMessage = styled(ErrorMsg)`
  text-align: left;
  height: 17px;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AssessmentButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 64px auto 40px auto;
  justify-content: space-around;
  align-items: flex-end;
`;

const RadioButtonArea = styled.div`
  display: flex;
  margin-top: -17px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
  }
`;

const AIAssessmentButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px auto 40px auto;
  justify-content: space-around;
  align-items: flex-end;
`;

const PickedRentType = { RESIDENT: '入居者支払い賃料', NET_INCOME: '手取り賃料' };

type Props = {
  doormanUnits: DoormanUnits;
  mansionData: DoormanMansion;
  isFromInformation: boolean;
  formValue?: AssessmentFormValue;
};

const InputSelectMansion = ({ doormanUnits, mansionData, isFromInformation, formValue }: Props) => {
  const {
    handleSubmit,
    setValue,
    resetField,
    control,
    formState: { errors },
    register,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      tenancyStatus: TenancyStatus.LEASE,
      rentManagementStatus: RentManagementStatus.SUBLEASE,
      rentManagementFeeType: RentManagementFeeType.YEN,
      bathroomType: BathroomType.SEPARATE_BATH_AND_TOILET,
    },
  });
  const dispatch = useDispatch();
  const [assessmentFlg, setAssessmentFlg] = useState(false);
  const [unitInfoList, setUnitInfoList] = useState<DoormanUnits>();
  const [unitInfoFlg, setUnitInfoFlg] = useState(false);
  const [unitSelectFlg, setUnitSelectFlg] = useState(false);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });
  const [isFirstRender, setIsFirstRender] = useState(true); // コンポーネントが1レンダー目であることを示すフラグ
  const personalInfoDetail = useSelector(assessment.selectors.fetchPersonalInfoDetailValue);
  const createAssessment = useSelector(assessment.selectors.createAssessment);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (assessmentFlg) {
      navigate(Routings.result.location, { state: { props: { assessment: createAssessment } } });
    }
  }, [createAssessment]);

  useEffect(() => {
    if (isFromInformation && !isEmpty(formValue)) {
      // 個人情報入力画面から戻ってきた時の入力値復元処理
      // 物件情報
      const roomInfo = formValue?.assessment?.roomInfo as Required<AssessmentRoom>;
      setValue('unitId', formValue?.assessment?.roomInfo.unitId);
      if (roomInfo.roomNumber) {
        setValue('roomNumber', roomInfo.roomNumber);
        setUnitSelectFlg(true);
        if (roomInfo.roomNumber === 'その他') {
          setValue('floorPlan', roomInfo.floorPlan);
          setValue('story', roomInfo.story.toString());
          setValue('unitSize', roomInfo.unitSize);
        }
      } else {
        setValue('floorPlan', roomInfo.floorPlan);
        setValue('story', roomInfo.story.toString());
        setValue('unitSize', roomInfo.unitSize);
      }
      setValue('bathroomType', roomInfo.bathroomType);

      // 諸条件
      setValue('tenancyStatus', formValue?.assessment?.tenancyStatus);
      setValue('rentManagementStatus', formValue?.assessment?.rentManagementStatus);
      if (formValue?.assessment?.tenancyStatus === TenancyStatus.LEASE) {
        setValue('rentPrice', formValue?.assessment?.rentPrice);
        if (formValue?.assessment?.rentManagementStatus === RentManagementStatus.OTHER) {
          setValue('rentType', formValue?.assessment?.rentType);
          setValue('rentManagementFee', formValue?.assessment?.rentManagementFee);
        }
      } else if (formValue?.assessment?.tenancyStatus === TenancyStatus.VACANCY) {
        setValue('rentPrice', formValue?.assessment?.rentPrice);
        setValue('rentManagementFeeType', formValue?.assessment?.rentManagementFeeType);
        if (formValue?.assessment?.rentManagementFeeType === RentManagementFeeType.PERCENT) {
          setValue('rentManagementFeeRate', formValue?.assessment?.rentManagementFeeRate);
        } else {
          setValue('rentManagementFeeYen', formValue?.assessment?.rentManagementFee);
        }
      }
      setValue('maintenanceFee', formValue?.assessment?.maintenanceFee as number);
    }
  }, []);

  useEffect(() => {
    if (doormanUnits && doormanUnits.units) {
      const sortUnits = doormanUnits.units.slice().sort(function (a, b) {
        if (a.number && b.number) {
          return parseInt(a.number) < parseInt(b.number) ? -1 : 1;
        }
        return -1;
      });
      sortUnits.push({ number: 'その他' });
      setUnitInfoList({ units: sortUnits });
      setUnitInfoFlg(doormanUnits.units.length > 0);
    } else {
      setUnitInfoList({});
      setUnitInfoFlg(false);
    }
  }, [doormanUnits]);

  const watchRoomNumber = useWatch({
    control,
    name: 'roomNumber',
    defaultValue: isFromInformation ? formValue?.assessment?.roomInfo.roomNumber : '',
  });

  /**
   * 部屋番号の変更に追随してunitIdを変更する処理。
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (watchRoomNumber) {
        unitInfoList?.units &&
          unitInfoList.units.forEach((unit) => {
            if (unit.number === watchRoomNumber) {
              setValue('roomNumber', watchRoomNumber);
              setValue('unitId', unit.id);
            }
            setUnitSelectFlg(true);
          });
      } else {
        setUnitSelectFlg(false);
        setValue('unitId', undefined);
        resetField('floorPlan');
        resetField('story');
        resetField('unitSize');
        resetField('bathroomType');
      }
    }
  }, [watchRoomNumber]);

  /**
   * 部屋番号が変更されたときに動的表示項目をリセットする処理。
   */
  useEffect(() => {
    if (!isFirstRender) {
      resetField('floorPlan');
      resetField('story');
      resetField('unitSize');
    }
  }, [watchRoomNumber]);

  const watchTenancyStatus = useWatch({
    control,
    name: 'tenancyStatus',
    defaultValue:
      isFromInformation && formValue?.assessment?.tenancyStatus
        ? formValue?.assessment?.tenancyStatus
        : TenancyStatus.LEASE,
  });

  /**
   * 入居状況が変更されたときに動的表示項目をリセットする処理。
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (watchTenancyStatus === TenancyStatus.LIVE) {
        resetField('rentPrice');
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      } else if (watchTenancyStatus === TenancyStatus.LEASE) {
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      }
    }
  }, [watchTenancyStatus]);

  const watchRentManagementStatus = useWatch({
    control,
    name: 'rentManagementStatus',
    defaultValue:
      isFromInformation && formValue?.assessment?.rentManagementStatus
        ? formValue?.assessment?.rentManagementStatus
        : RentManagementStatus.SUBLEASE,
  });

  /**
   * 賃貸管理状況が変更されたときに動的表示項目をリセットする処理
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (watchRentManagementStatus === RentManagementStatus.SUBLEASE) {
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      }
    }
  }, [watchRentManagementStatus]);

  const watchRentManagementFeeType = useWatch({
    control,
    name: 'rentManagementFeeType',
    defaultValue:
      isFromInformation && formValue?.assessment?.rentManagementFeeType
        ? formValue?.assessment?.rentManagementFeeType
        : RentManagementFeeType.YEN,
  });

  /**
   * 賃貸管理手数料タイプが変更されたときに賃貸管理手数料をリセットする処理
   */
  useEffect(() => {
    if (watchRentManagementFeeType === RentManagementFeeType.YEN) {
      resetField('rentManagementFeeRate');
    } else if (watchRentManagementFeeType === RentManagementFeeType.PERCENT) {
      resetField('rentManagementFeeYen');
    }
  }, [watchRentManagementFeeType]);

  const watchUnitId = useWatch({
    control,
    name: 'unitId',
    defaultValue: isFromInformation ? formValue?.assessment?.roomInfo.unitId : undefined,
  });

  const watchBathroomType = useWatch({
    control,
    name: 'bathroomType',
    defaultValue:
      isFromInformation && formValue?.assessment?.roomInfo?.bathroomType
        ? formValue?.assessment?.roomInfo?.bathroomType
        : BathroomType.SEPARATE_BATH_AND_TOILET,
  });

  const watchRentType = useWatch({
    control,
    name: 'rentType',
    defaultValue:
      isFromInformation && formValue?.assessment?.rentType ? formValue?.assessment?.rentType : RentType.RESIDENT,
  });

  const onSubmit: SubmitHandler<FormState> = (data: FormState) => {
    const addressInfo: AssessmentAddress = {
      prefectureName: '',
      cityName: '',
      townName: '',
    };
    if (mansionData.address) {
      addressInfo.prefectureName = mansionData.address.pref as string;
      addressInfo.cityName = mansionData.address.city as string;
      addressInfo.townName = mansionData.address.town as string;
    }
    const mansionInfo: AssessmentMansion = {
      mansionId: mansionData.id?.toString(),
      mansionName: mansionData.name,
      builtDate: mansionData.builtDate as string,
      structure: 'その他',
    };
    const roomInfo: AssessmentRoom = {
      unitId: data.unitId,
      floorPlan: data.floorPlan,
      story: Number(data.story),
      unitSize: data.unitSize,
      roomNumber: data.roomNumber,
      balconySize: data.balconySize,
      bathroomType: data.bathroomType as BathroomType,
    };

    // Assessmentの型にあてはめる
    const assessmentData: Assessment = {
      addressInfo: addressInfo,
      mansionInfo: mansionInfo,
      roomInfo: roomInfo,
      tenancyStatus: data.tenancyStatus as TenancyStatus,
      rentManagementStatus: data.rentManagementStatus as RentManagementStatus,
      maintenanceFee: data.maintenanceFee,
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
    };
    if (data.tenancyStatus === TenancyStatus.LEASE || data.tenancyStatus === TenancyStatus.VACANCY) {
      assessmentData.rentPrice = data.rentPrice;
      if (data.rentManagementStatus === RentManagementStatus.OTHER) {
        assessmentData.rentType = watchRentType as RentType;
        assessmentData.rentManagementFee = data.rentManagementFee;
      }
    }
    if (watchRentManagementFeeType && data.tenancyStatus === TenancyStatus.VACANCY) {
      assessmentData.rentManagementFeeType = watchRentManagementFeeType as RentManagementFeeType;
      if (watchRentManagementFeeType === RentManagementFeeType.YEN) {
        assessmentData.rentManagementFee = data.rentManagementFeeYen;
      } else {
        assessmentData.rentManagementFeeRate = data.rentManagementFeeRate;
      }
    }

    const newAssessment: Assessment = {
      ...assessmentData,
      ...personalInfoDetail,
      partnerId: getPartneridForSessionStorage(),
    } as Assessment;
    if (personalInfoDetail) {
      dispatch(assessment.operations.createAssessment(newAssessment));
      setAssessmentFlg(true);
    } else {
      dispatch(
        assessment.operations.keepAssessmentFormValue({ assessment: assessmentData, isFromSearchMansion: true })
      );
      navigate(Routings.information.location, {
        state: { assessmentFormValue: { assessment: assessmentData, isFromSearchMansion: true } },
      });
    }
  };

  const BathroomEquipmentArea = () => {
    return (
      <>
        <Th>
          <InnerTh>
            <ThLabel htmlFor="bathroomType">浴室設備</ThLabel>
            <Required>必須</Required>
          </InnerTh>
        </Th>
        <Td>
          <InnerTd>
            <RadioButtonDetailArea>
              <Controller
                name="bathroomType"
                control={control}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    ref={null}
                    checked={watchBathroomType === BathroomType.SEPARATE_BATH_AND_TOILET}
                    id={'separate_bath_and_toilet'}
                    name={'bathroomType'}
                    label={`${BathroomType.SEPARATE_BATH_AND_TOILET}`}
                    value={BathroomType.SEPARATE_BATH_AND_TOILET}
                  />
                )}
              />
              <Controller
                name="bathroomType"
                control={control}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    ref={null}
                    checked={watchBathroomType === BathroomType.UNIT_BATH}
                    id={'unit_bath'}
                    name={'bathroomType'}
                    label={`${BathroomType.UNIT_BATH}`}
                    value={BathroomType.UNIT_BATH}
                  />
                )}
              />
            </RadioButtonDetailArea>
          </InnerTd>
          <ErrorMessage>{errors.bathroomType && errors.bathroomType?.message}</ErrorMessage>
        </Td>
      </>
    );
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GuideTextWrapper>
          <GuideText>物件情報をご入力ください　</GuideText>
        </GuideTextWrapper>
        {/* 住戸情報がある場合 */}
        {unitInfoFlg && unitInfoList?.units && (
          <RoomNumberArea>
            <FormContainer>
              <tbody>
                <Row>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="roomNumber">部屋番号</ThLabel>
                      <Required>必須</Required>
                    </InnerTh>
                  </Th>
                  <Td>
                    <InnerTd>
                      <SelectWrapper>
                        <Controller
                          name="roomNumber"
                          control={control}
                          render={() => (
                            <StyledSelect id="roomNumber" {...register('roomNumber')}>
                              <SelectOption key={0} value={''}>
                                {UNSELECTED}
                              </SelectOption>
                              {unitInfoList.units?.map((unitInfo, i) => (
                                <SelectOption key={i} value={unitInfo.number}>
                                  {unitInfo.number}
                                </SelectOption>
                              ))}
                            </StyledSelect>
                          )}
                        />
                        <TdLabel>号室</TdLabel>
                      </SelectWrapper>
                    </InnerTd>
                  </Td>
                </Row>
                {watchUnitId && (
                  <Row>
                    <BathroomEquipmentArea />
                  </Row>
                )}
              </tbody>
            </FormContainer>
          </RoomNumberArea>
        )}
        {/* マンション選択したけど住戸情報がない、または部屋番号に「その他」が選択された場合、入力フォームが表示される */}
        {(!unitInfoFlg || unitSelectFlg) && (
          <>
            {(!unitInfoFlg || !watchUnitId) && (
              // 住戸情報があり「その他(unitId が未指定)」が選択されている場合と、住戸情報がない場合以下の表示
              <FormContainer>
                <tbody>
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="floorPlan">間取り</ThLabel>
                        <Required>必須</Required>
                      </InnerTh>
                    </Th>
                    <Td>
                      <Controller
                        name="floorPlan"
                        control={control}
                        render={({ field }) => (
                          <StyledSelect id="floorPlan" {...field}>
                            <option value="" hidden>
                              選択して下さい
                            </option>
                            {Object.entries(FloorPlanArray).map(([key, floorPlan]) => (
                              <option key={key}>{floorPlan.value}</option>
                            ))}
                          </StyledSelect>
                        )}
                      />
                      <ErrorMessage>{errors.floorPlan && errors.floorPlan?.message}</ErrorMessage>
                    </Td>
                  </Row>
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="story">所在階</ThLabel>
                        <Required>必須</Required>
                      </InnerTh>
                    </Th>
                    <Td>
                      <InnerTd>
                        <TextFieldWrapper>
                          <Controller
                            name="story"
                            control={control}
                            render={({ field }) => <TextField placeholder="例：1" type="text" id="story" {...field} />}
                          />
                          <TdLabel>階</TdLabel>
                        </TextFieldWrapper>
                      </InnerTd>
                      <ErrorMessage>{errors.story && errors.story?.message}</ErrorMessage>
                    </Td>
                  </Row>
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="unitSize">専有面積</ThLabel>
                        <Required>必須</Required>
                      </InnerTh>
                    </Th>
                    <Td>
                      <InnerTd>
                        <TextFieldWrapper>
                          <Controller
                            name="unitSize"
                            control={control}
                            render={({ field }) => (
                              <TextField placeholder="例：10" type="text" id="unitSize" {...field} />
                            )}
                          />
                          <TdLabel>㎡</TdLabel>
                        </TextFieldWrapper>
                      </InnerTd>
                      <ErrorMessage>{errors.unitSize && errors.unitSize?.message}</ErrorMessage>
                    </Td>
                  </Row>
                  <Row>
                    <BathroomEquipmentArea />
                  </Row>
                </tbody>
              </FormContainer>
            )}
            <GuideTextWrapper>
              <GuideText>諸条件をご入力ください</GuideText>
            </GuideTextWrapper>
            <FormContainer>
              <tbody>
                <Row>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="tenancyStatus">入居状況</ThLabel>
                      <Required>必須</Required>
                    </InnerTh>
                  </Th>
                  <Td>
                    <InnerTd>
                      <RadioButtonArea>
                        <Controller
                          name="tenancyStatus"
                          control={control}
                          render={({ field }) => (
                            <RadioButton
                              {...field}
                              ref={null}
                              checked={watchTenancyStatus === TenancyStatus.LEASE}
                              id={'lease'}
                              name={'tenancyStatus'}
                              label={'賃貸中'}
                              value={TenancyStatus.LEASE}
                            />
                          )}
                        />
                        <Controller
                          name="tenancyStatus"
                          control={control}
                          render={({ field }) => (
                            <RadioButton
                              {...field}
                              ref={null}
                              checked={watchTenancyStatus === TenancyStatus.VACANCY}
                              id={'vacancy'}
                              name={'tenancyStatus'}
                              label={'空室'}
                              value={TenancyStatus.VACANCY}
                            />
                          )}
                        />
                        <Controller
                          name="tenancyStatus"
                          control={control}
                          render={({ field }) => (
                            <RadioButton
                              {...field}
                              ref={null}
                              checked={watchTenancyStatus === TenancyStatus.LIVE}
                              id={'live'}
                              name={'tenancyStatus'}
                              label={'居住中（自用）'}
                              value={TenancyStatus.LIVE}
                            />
                          )}
                        />
                      </RadioButtonArea>
                    </InnerTd>
                  </Td>
                </Row>
                {/* 賃貸中選択時に表示 */}
                {watchTenancyStatus === TenancyStatus.LEASE && (
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="rentManagementStatus">賃貸管理状況</ThLabel>
                        <Required>必須</Required>
                      </InnerTh>
                    </Th>
                    <Td>
                      <InnerTd>
                        <RadioButtonArea>
                          <Controller
                            name="rentManagementStatus"
                            control={control}
                            render={({ field }) => (
                              <RadioButton
                                {...field}
                                ref={null}
                                checked={watchRentManagementStatus === RentManagementStatus.SUBLEASE}
                                id={'sublease'}
                                name={'rentManagementStatus'}
                                label={'サブリース'}
                                value={RentManagementStatus.SUBLEASE}
                              />
                            )}
                          />
                          <Controller
                            name="rentManagementStatus"
                            control={control}
                            render={({ field }) => (
                              <RadioButton
                                {...field}
                                ref={null}
                                checked={watchRentManagementStatus === RentManagementStatus.OTHER}
                                id={'other'}
                                name={'rentManagementStatus'}
                                label={'サブリース以外'}
                                value={RentManagementStatus.OTHER}
                              />
                            )}
                          />
                        </RadioButtonArea>
                      </InnerTd>
                    </Td>
                  </Row>
                )}
              </tbody>
              {/* サブリース選択時の表示項目 */}
              {watchTenancyStatus === TenancyStatus.LEASE &&
                (watchRentManagementStatus === RentManagementStatus.SUBLEASE ? (
                  <tbody>
                    <Row>
                      <Th>
                        <InnerTh>
                          <ThLabel htmlFor="rentPrice">手取り賃料</ThLabel>
                          <Required>必須</Required>
                        </InnerTh>
                      </Th>
                      <Td>
                        <InnerTd>
                          <TextFieldWrapper>
                            <Controller
                              name="rentPrice"
                              control={control}
                              render={({ field }) => (
                                <TextField placeholder="例：100000" type="text" id="rentPrice" {...field} />
                              )}
                            />
                            <TdLabel>円/月</TdLabel>
                          </TextFieldWrapper>
                        </InnerTd>
                        <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                      </Td>
                    </Row>
                  </tbody>
                ) : (
                  <tbody>
                    <RentPriceRow>
                      <Th>
                        <InnerTh>
                          <ThLabel htmlFor="rentType">賃料</ThLabel>
                          <Required>必須</Required>
                        </InnerTh>
                      </Th>
                      <Td>
                        <InnerTd>
                          <Controller
                            name="rentType"
                            control={control}
                            render={({ field }) => (
                              <StyledSelect
                                id="rentType"
                                {...field}
                                defaultValue={
                                  isFromInformation ? formValue?.assessment?.rentType : PickedRentType.RESIDENT
                                }
                              >
                                {Object.entries(PickedRentType).map(([key, type]) => (
                                  <SelectOption key={key}>{type}</SelectOption>
                                ))}
                              </StyledSelect>
                            )}
                          />
                        </InnerTd>
                      </Td>
                    </RentPriceRow>
                    <RentPriceRow style={{ borderTop: 'hidden' }}>
                      {!isSpMode && <Th />}
                      <RentPriceTd>
                        <InnerTd>
                          <TextFieldWrapper>
                            <Controller
                              name="rentPrice"
                              control={control}
                              render={({ field }) => <TextField placeholder="例：50000" type="text" {...field} />}
                            />
                            <TdLabel>円/月</TdLabel>
                          </TextFieldWrapper>
                        </InnerTd>
                        <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                      </RentPriceTd>
                    </RentPriceRow>
                    <Row>
                      <Th>
                        <InnerTh>
                          <ThLabel htmlFor="rentManagementFee">賃貸管理手数料</ThLabel>
                        </InnerTh>
                      </Th>
                      <Td>
                        <InnerTd>
                          <TextFieldWrapper>
                            <Controller
                              name="rentManagementFee"
                              control={control}
                              render={({ field }) => <TextField placeholder="例：50000" type="text" {...field} />}
                            />
                            <TdLabel>円/月</TdLabel>
                          </TextFieldWrapper>
                        </InnerTd>
                        <ErrorMessage>{errors.rentManagementFee && errors.rentManagementFee?.message}</ErrorMessage>
                      </Td>
                    </Row>
                  </tbody>
                ))}
              {/* 入居情報が空室の場合 */}
              {watchTenancyStatus === TenancyStatus.VACANCY && (
                <tbody>
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="rentPrice">募集家賃</ThLabel>
                      </InnerTh>
                    </Th>
                    <Td>
                      <InnerTd>
                        <TextFieldWrapper>
                          <Controller
                            name="rentPrice"
                            control={control}
                            render={({ field }) => (
                              <TextField placeholder="例：100000" type="text" id="rentPrice" {...field} />
                            )}
                          />
                          <TdLabel>円/月</TdLabel>
                        </TextFieldWrapper>
                      </InnerTd>
                      <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                    </Td>
                  </Row>
                  <Row>
                    <Th>
                      <InnerTh>
                        <ThLabel htmlFor="rentManagementFeeRate">賃貸管理手数料</ThLabel>
                      </InnerTh>
                    </Th>
                    <Td>
                      <InnerTd>
                        <TextFieldWrapper>
                          {watchRentManagementFeeType === RentManagementFeeType.PERCENT && (
                            <Controller
                              name="rentManagementFeeRate"
                              control={control}
                              render={({ field }) => (
                                <RentManagementFeeTextField
                                  placeholder="例：50"
                                  type="text"
                                  id="rentManagementFeeRate"
                                  {...field}
                                />
                              )}
                            />
                          )}
                          {watchRentManagementFeeType === RentManagementFeeType.YEN && (
                            <Controller
                              name="rentManagementFeeYen"
                              control={control}
                              render={({ field }) => (
                                <RentManagementFeeTextField
                                  placeholder="例：50000"
                                  type="text"
                                  id="rentManagementFeeRate"
                                  {...field}
                                />
                              )}
                            />
                          )}
                          <TdLabel>
                            <Controller
                              name="rentManagementFeeType"
                              control={control}
                              render={() => (
                                <RentManagementFeeTypeSelect
                                  id="rentManagementFeeType"
                                  aria-label="賃貸管理手数料（単位）"
                                  {...register('rentManagementFeeType')}
                                  defaultValue={
                                    isFromInformation && formValue?.assessment?.rentManagementFeeType
                                      ? formValue?.assessment?.rentManagementFeeType
                                      : RentManagementFeeType.YEN
                                  }
                                >
                                  {Object.entries(RentManagementFeeType).map(([key, type]) => (
                                    <SelectOption key={key}>{type}</SelectOption>
                                  ))}
                                </RentManagementFeeTypeSelect>
                              )}
                            />
                            {!isSpMode && <span style={{ paddingLeft: '10px' }}>/月</span>}
                          </TdLabel>
                        </TextFieldWrapper>
                        {isSpMode && <TdLabel>/月</TdLabel>}
                      </InnerTd>
                      <ErrorMessage>
                        {errors.rentManagementFeeRate && errors.rentManagementFeeRate?.message}
                        {errors.rentManagementFeeYen && errors.rentManagementFeeYen?.message}
                      </ErrorMessage>
                    </Td>
                  </Row>
                </tbody>
              )}
              <tbody>
                <Row>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="maintenanceFee">建物管理費と修繕積立金の合計</ThLabel>
                      <Required>必須</Required>
                    </InnerTh>
                  </Th>
                  <Td>
                    <InnerTd>
                      <TextFieldWrapper>
                        <Controller
                          name="maintenanceFee"
                          control={control}
                          render={({ field }) => (
                            <TextField placeholder="例：50000" type="text" id="maintenanceFee" {...field} />
                          )}
                        />
                        <TdLabel>円/月</TdLabel>
                      </TextFieldWrapper>
                    </InnerTd>
                    <ErrorMessage>{errors.maintenanceFee && errors.maintenanceFee?.message}</ErrorMessage>
                  </Td>
                </Row>
              </tbody>
            </FormContainer>
            {personalInfoDetail ? (
              <>
                <TermsInArea />
                <AIAssessmentButtonWrapper>
                  <SubmitButton withTerms={true} label="AI査定結果を見る" onClick={handleSubmit(onSubmit)} />
                </AIAssessmentButtonWrapper>
              </>
            ) : (
              <AssessmentButtonWrapper>
                <AssessmentButton onClick={handleSubmit(onSubmit)}>この条件で査定する</AssessmentButton>
              </AssessmentButtonWrapper>
            )}
          </>
        )}
      </form>
    </Wrapper>
  );
};

export default InputSelectMansion;
