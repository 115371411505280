import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';

export const schema = yup.object({
  // 売主TOP検索
  prefId: yup.number().typeError(YupErrorMessage.number.numeric).required(YupErrorMessage.required).label('都道府県'),
  cityId: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
    .label('市区町村'),
  name: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(255, YupErrorMessage.string.max)
    .label('マンション名'),
});

export type FormState = yup.InferType<typeof schema>;
