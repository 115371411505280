import React, { useState } from 'react';
import ReactModal from 'react-modal';
import Button from '@/components/atoms/Button';
import styled from 'styled-components';
import flexCenter from '@/styles/mixins/flexCenter';
import { DoormanMansion, DoormanMansions } from 'openapi/kusabi-frontgw-spec';
import { Breakpoints } from '@/constants/constants';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import theme from '@/styles/theme';
import { navigate } from 'gatsby';
import { Routings } from '@/common/routings';

const StyledReactModal = styled(ReactModal)`
  width: 78%;
  max-width: 1062px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 90%;
    height: 953px;
  }
`;

const TitleArea = styled.div`
  width: 270px;
  height: 44px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 32px;
  }
`;

const Title = styled.label`
  text-align: center;
  font: ${theme.fonts.bold_30_45};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_22_33};
  }
`;

const MansionCountArea = styled.div`
  width: 100%;
  height: 44px;
  margin-top: 2%;
`;

const MansionCount = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  font: ${theme.fonts.bold_30_45};
  letter-spacing: 0px;
  text-align: center;
  color: #d1a827;
  opacity: 1;
`;

const CountLabel = styled.label`
  font: ${theme.fonts.bold_16_45};
  letter-spacing: 0px;
  vertical-align: baseline;
  color: #d1a827;
`;

const GuideMessage = styled.span`
  position: absolute;
  bottom: 0;
  margin-left: 5px;
  white-space: nowrap;
  font: ${theme.fonts.bold_12_45};
  color: rgba(0, 0, 0, 0.5);
`;

const NoResultMessageArea = styled.div`
  width: 446px;
  height: 88px;
  margin: 61px auto 60px auto;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 155px;
  }
`;

const NoResultMessage = styled.label`
  text-align: center;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  white-space: nowrap;
`;

const CenterWrapper = styled.div`
  ${flexCenter}
  margin-top: 2%;
  flex-direction: column;
`;

const PseudoButton = styled.div`
  width: 190px;
`;

const CancelResearchButton = styled(Button)`
  width: 190px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font: ${theme.fonts.bold_14_45};
  line-height: 1;
  color: #4c586f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding: 0;
  }
`;

const NextButton = styled(Button)`
  width: 299px;
  height: 64px;
  padding: 0;
  font: ${theme.fonts.bold_22_33};
  background: #4c586f;
  border-radius: 4px;
  margin-left: 2%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 64px;
  }
`;

const ButtonCenterWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 5% 10%;
  display: flex;
  flex-direction: row;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 90%;
    height: auto;
    margin-top: 0px;
    padding: 5% 0px 0px 0px;
    flex-direction: column-reverse;
  }
`;
const MansionList = styled.ul`
  width: 96%;
  overflow-y: scroll;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 28px 0px 0px;
  padding: 0px;
  justify-content: center;
  text-align: left;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const MansionInfoArea = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  height: max-content;
  margin: 5px 0px 5px 0px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  position: relative;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const MansionInfo = styled.button`
  justify-content: center;
  align-items: center;
  width: max-content;
  list-style: none;
  padding: 0;
  font-size: 14px;
  white-space: normal;
  margin-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    overflow: hidden;
  }
`;

const ScrollBox = styled.div`
  width: 90%;
  height: 280px;
  margin-left: 28px;
  border: 1px solid #000;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    margin-top: 5px;
    background: #f1f1f1;
    box-shadow: 0 0 5px #fff inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    height: 81px;
  }
  border: none;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-left: 0px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

const MAX_MANSION_COUNT = 300;

type Props = {
  doormanMansions: DoormanMansions;
  isModalOpen: boolean;
  isMansionExist: boolean;
  changeIsOpen: (isOpen: boolean) => void;
  onClickSelectProperty: (id: number) => void;
};

const PropertySelectModal = ({
  doormanMansions,
  isModalOpen,
  isMansionExist,
  changeIsOpen,
  onClickSelectProperty,
}: Props) => {
  const [selectedMansionId, setSelectedMansionId] = useState(0);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });

  const mansionCount = doormanMansions.mansions?.length || 0;

  const onClickMansion = (id: number) => {
    if (id === selectedMansionId) {
      setSelectedMansionId(0);
    } else {
      setSelectedMansionId(id);
    }
  };

  const handleOnSelectProperty = () => {
    onClickSelectProperty(selectedMansionId);
    changeIsOpen(false);
  };

  const onClickNextButton = () => {
    navigate(Routings.condition.location);
  };

  return (
    <StyledReactModal
      aria={{ labelledby: 'heading', describedby: 'description' }}
      style={{
        content: { height: isMansionExist ? '648px' : isSpMode ? '617px' : '502px', outline: 'none' },
        overlay: { zIndex: 999999 },
      }}
      isOpen={isModalOpen}
    >
      <TitleArea>
        <Title id="heading">マンション検索結果</Title>
      </TitleArea>
      <MansionCountArea id="description">
        <MansionCount>
          {mansionCount}
          {mansionCount >= MAX_MANSION_COUNT ? (
            <>
              <CountLabel>件以上</CountLabel>
              <GuideMessage>※上位300件を表示</GuideMessage>
            </>
          ) : (
            <CountLabel>件</CountLabel>
          )}
        </MansionCount>
      </MansionCountArea>
      {!isMansionExist ? (
        <NoResultMessageArea>
          <NoResultMessage>
            <MediaQuery query="(max-width: 900px)">
              申し訳ございません。
              <br />
              条件に合うマンションが
              <br />
              見つかりませんでした。
              <br />
              条件を変えて再検索するか、
              <br />
              査定条件を直接ご入力ください。
            </MediaQuery>
            <MediaQuery query="(min-width: 901px)">
              申し訳ございません。
              <br />
              条件に合うマンションが見つかりませんでした。
              <br />
              条件を変えて再検索するか、査定条件を直接ご入力ください。
            </MediaQuery>
          </NoResultMessage>
        </NoResultMessageArea>
      ) : (
        <CenterWrapper>
          <ScrollBox>
            <MansionList>
              {doormanMansions.mansions?.map((mansion: DoormanMansion) => (
                <MansionInfoArea
                  style={{ backgroundColor: mansion.id === selectedMansionId ? '#d1a827' : '#f3f3f3' }}
                  key={mansion.id}
                  onClick={() => onClickMansion(mansion.id as number)}
                >
                  <MansionInfo
                    style={{
                      color: mansion.id === selectedMansionId ? '#f3f3f3' : '#333333',
                      font:
                        mansion.id === selectedMansionId
                          ? 'bold normal 16px/32px Noto Sans JP'
                          : 'normal normal 16px/32px Noto Sans JP',
                    }}
                  >
                    {isSpMode ? (
                      <>
                        {mansion.name}
                        <br />
                        {mansion.address?.pref}
                        {mansion.address?.city}
                        {mansion.address?.town}
                      </>
                    ) : (
                      `${mansion.name} (${mansion.address?.pref} ${mansion.address?.city} ${mansion.address?.town})`
                    )}
                  </MansionInfo>
                </MansionInfoArea>
              ))}
            </MansionList>
          </ScrollBox>
        </CenterWrapper>
      )}
      <ButtonCenterWrapper>
        <CancelResearchButton onClick={() => changeIsOpen(false)}>
          {isMansionExist ? 'キャンセル' : '再検索する'}
        </CancelResearchButton>
        <NextButton
          disabled={isMansionExist && selectedMansionId === 0}
          style={{ backgroundColor: isMansionExist && selectedMansionId === 0 ? '#CCCCCC' : '#4c586f' }}
          onClick={isMansionExist ? handleOnSelectProperty : onClickNextButton}
        >
          {isMansionExist ? 'このマンションを選択' : '査定条件を直接入力する'}
        </NextButton>
        <PseudoButton />
      </ButtonCenterWrapper>
    </StyledReactModal>
  );
};

export default PropertySelectModal;
