import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '@/components/atoms/Button';
import * as address from '@/state/modules/doorman';
import flexCenter from '@/styles/mixins/flexCenter';
import PropertySelectModal from '@/components/organisms/SearchByMansionName/PropertySelectModal';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputSelectMansion from '@/components/organisms/SearchByMansionName/InputSelectMansion';
import { Breakpoints, Prefectures, UNSELECTED } from '@/constants/constants';
import { FormState, schema } from './state';
import { DoormanMansion, DoormanUnits } from 'openapi/kusabi-frontgw-spec';
import { find } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import theme from '@/styles/theme';
import { navigate } from 'gatsby';
import { Routings } from '@/common/routings';
import { Main } from '@/components/layout/common';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';
import PageTitle from '@/components/atoms/PageTitle';
import * as assessment from '@/state/modules/assessment';
import Select from '@/components/atoms/Select';
import ErrorMsg from '@/components/atoms/ErrorMessage';

const InnerMain = styled.div`
  width: 100%;
  max-width: 1062px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 5% 120px 5%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0px 3.5% 60px 3.5%;
  }
`;

const PseudoButton = styled.div`
  width: 269px;
`;

const SearchButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f;
  font: ${theme.fonts.bold_22_33};
  border-radius: 4px;
  opacity: 1;
  white-space: nowrap;
  margin-left: 2%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ConditionButton = styled(Button)`
  width: 269px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  font: ${theme.fonts.bold_14_45};
  line-height: 1;
  color: #4c586f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 0;
  }
`;

const SearchButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 8px auto 40px auto;
  justify-content: space-around;
  align-items: flex-end;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const FormItem = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: center;
`;

const FormItemLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_16_24};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
`;

const FormItemLabelArea = styled.div`
  width: 299px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const FormItemMansionLabelArea = styled.div`
  width: 626px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: 'flex';
  margin-top: 1vh;
  width: 100%;
`;

const StyledInput = styled.input`
  font: ${theme.fonts.normal_16_32};
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  width: 626px;
  height: 40px;
  padding: 1vw;
  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const RequiredLabelLayout = styled.div`
  width: 30px;
  height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1a827;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
`;

const LabelRequiredText = styled.div`
  width: 20px;
  height: 15px;
  text-align: center;
  font: ${theme.fonts.bold_10_20};
  letter-spacing: 0px;
  color: #d1a827;
  opacity: 1;
`;

const CenterWrapper = styled.div`
  ${flexCenter}
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const MansionInfoContainer = styled.div`
  width: 100%;
  height: fit-content;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: space-between;
  .hide {
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 190px;
    flex-direction: column;
  }
`;

const SelectedMansionLabel = styled.label`
  width: 70%;
  text-align: left;
  font: ${theme.fonts.bold_16_32};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 20px 20px 0px 0px;
  }
`;

const ReSelectMansionButton = styled(Button)`
  width: 170px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  margin: 1% 20px 1% 0px;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 20px;
  }
` as typeof Button;

const ReSelectButtonLabel = styled.label`
  text-align: center;
  font: ${theme.fonts.bold_14_33};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  white-space: nowrap;
`;

const ErrorMessage = styled(ErrorMsg)`
  text-align: left;
  height: 17px;
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 299px;
  height: 40px;
  margin-top: 1vh;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const SelectBoxOption = styled.option`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;

const SearchConditionArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 366px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-top: 2vh;
  align: center;
  padding: 2%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SearchConditionRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 626px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 21px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0px;
  }
`;

const SubTitle = styled.h2`
  width: 100%;
  font: ${theme.fonts.bold_22_33};
  color: #4c586f;
  padding-left: 20px;
  margin-bottom: 0px;
`;

const GuideText = styled.h3`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
`;

type Props = {
  isFromInformation: boolean;
};

const Search = ({ isFromInformation }: Props) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      prefId: 13,
    },
  });
  const dispatch = useDispatch();
  const doormanUnits = useSelector(address.selectors.getDoormanUnits);
  const doormanCities = useSelector(address.selectors.getDoormanCities);
  const doormanMansions = useSelector(address.selectors.getDoormanMansions);
  const [unitsData, setUnitsData] = useState<DoormanUnits>();
  const [mansionData, setMansionData] = useState<DoormanMansion>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });
  const formValue = useSelector(assessment.selectors.fetchAssessmentFormValue);

  useEffect(() => {
    if (isFromInformation && formValue) {
      setIsDisplaySearchArea(false);
      setMansionData({
        id: Number(formValue.assessment?.mansionInfo.mansionId),
        name: formValue.assessment?.mansionInfo.mansionName,
        address: {
          pref: formValue.assessment?.addressInfo.prefectureName,
          city: formValue.assessment?.addressInfo.cityName,
          town: formValue.assessment?.addressInfo.townName,
        },
        builtDate: formValue.assessment?.mansionInfo.builtDate,
      });
    }
    // 東京のidで市区町村取得
    dispatch(address.operations.getCities(Number(Prefectures[0].id)));
    return () => {
      setMansionData({ address: {} });
    };
  }, []);

  const changeIsOpen = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const [isDisplaySearchArea, setIsDisplaySearchArea] = useState(true);

  const onClickSelectProperty = (id: number) => {
    dispatch(address.operations.getUnits(id));
    dispatch(address.operations.getCities(getValues().prefId));
    setIsModalOpen(false);
    if (find(doormanMansions.mansions, ['id', id])) {
      setMansionData(find(doormanMansions.mansions, ['id', id]));
    }
  };

  useEffect(() => {
    setUnitsData(doormanUnits);
  }, [doormanUnits]);

  useEffect(() => {
    if (mansionData) setIsDisplaySearchArea(false);
  }, [mansionData]);

  const handleOnChangeSelect = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    if (ev.currentTarget.value) {
      dispatch(address.operations.getCities(Number(ev.currentTarget.value)));
      setValue('cityId', undefined);
    }
  };

  const onSubmit: SubmitHandler<FormState> = (data: FormState) => {
    dispatch(
      address.operations.searchMansions({
        prefId: data.prefId,
        cityId: data.cityId,
        name: data.name,
      })
    );
    setIsModalOpen(true);
  };

  const onClickReSelectMansionButton = () => {
    setValue('prefId', Prefectures[0].id);
    setValue('cityId', undefined);
    setValue('name', '');
    clearErrors();
    dispatch(assessment.operations.clearAssessmentFormValue());
    setIsDisplaySearchArea(true);
  };

  return (
    <Main>
      <PageTitle>マンションAI査定</PageTitle>
      <InnerMain>
        <SubTitleWrapper>
          {isDisplaySearchArea && <SlashIcon />}
          <SubTitle style={{ marginLeft: isDisplaySearchArea ? '0px' : '-20px' }}>
            {isDisplaySearchArea ? 'マンション情報を自動で入力' : '査定マンション'}
          </SubTitle>
        </SubTitleWrapper>
        {isDisplaySearchArea && (
          <>
            <GuideText>マンションを検索することで、入力の手間を省けます　</GuideText>
            <SearchConditionArea>
              <SearchConditionRow>
                <FormItem>
                  <FormItemLabelArea>
                    <FormItemLabel htmlFor="prefId">都道府県</FormItemLabel>
                    <RequiredLabelLayout>
                      <LabelRequiredText>必須</LabelRequiredText>
                    </RequiredLabelLayout>
                  </FormItemLabelArea>
                  <SelectWrapper>
                    <Select id="prefId" {...register('prefId')} onChange={(ev) => handleOnChangeSelect(ev)}>
                      {Prefectures.map((pref) => (
                        <SelectBoxOption key={pref.id} value={pref.id}>{`${pref.name}`}</SelectBoxOption>
                      ))}
                    </Select>
                  </SelectWrapper>
                </FormItem>
                <FormItem>
                  <FormItemLabelArea>
                    <FormItemLabel htmlFor="cityId">市区町村</FormItemLabel>
                  </FormItemLabelArea>
                  <SelectWrapper>
                    <Select id="cityId" {...register('cityId')}>
                      <SelectBoxOption key={0} value={''}>
                        {UNSELECTED}
                      </SelectBoxOption>
                      {doormanCities?.cities &&
                        doormanCities.cities.map((city) => (
                          <SelectBoxOption key={city.id} value={city.id}>{`${city.name}`}</SelectBoxOption>
                        ))}
                    </Select>
                  </SelectWrapper>
                </FormItem>
              </SearchConditionRow>
              <SearchConditionRow>
                <FormItem>
                  <FormItemMansionLabelArea>
                    <FormItemLabel htmlFor="">マンション名</FormItemLabel>
                    <RequiredLabelLayout>
                      <LabelRequiredText>必須</LabelRequiredText>
                    </RequiredLabelLayout>
                  </FormItemMansionLabelArea>
                  <InputWrapper>
                    <StyledInput placeholder="例：ワークスマンション" type="text" {...register('name')} />
                  </InputWrapper>
                  <ErrorMessage>{errors?.name && errors.name?.message}</ErrorMessage>
                </FormItem>
              </SearchConditionRow>
              <SearchButtonArea>
                <ConditionButton
                  onClick={() => {
                    navigate(Routings.condition.location);
                  }}
                >
                  自動入力せずに査定する
                </ConditionButton>
                <SearchButton onClick={handleSubmit(onSubmit)}>マンションを検索</SearchButton>
                <PseudoButton />
              </SearchButtonArea>
            </SearchConditionArea>
            {isModalOpen && (
              <PropertySelectModal
                doormanMansions={doormanMansions}
                isModalOpen={isModalOpen}
                changeIsOpen={changeIsOpen}
                isMansionExist={doormanMansions.mansions?.length !== 0}
                onClickSelectProperty={onClickSelectProperty}
              />
            )}
          </>
        )}
        {!isDisplaySearchArea && mansionData && (
          <CenterWrapper>
            <MansionInfoContainer key={mansionData.id}>
              <SelectedMansionLabel>
                {isSpMode ? (
                  <>
                    {`${mansionData.name}`}
                    <br />
                    {`(${mansionData.address?.pref}${mansionData.address?.city}${mansionData.address?.town})`}
                  </>
                ) : (
                  <>{`${mansionData.name} (${mansionData.address?.pref}${mansionData.address?.city}${mansionData.address?.town})`}</>
                )}
              </SelectedMansionLabel>
              <ReSelectMansionButton onClick={onClickReSelectMansionButton}>
                <ReSelectButtonLabel>マンションを選び直す</ReSelectButtonLabel>
              </ReSelectMansionButton>
            </MansionInfoContainer>
            <InputSelectMansion
              isFromInformation={isFromInformation}
              doormanUnits={unitsData as DoormanUnits}
              mansionData={mansionData}
              formValue={formValue}
            />
          </CenterWrapper>
        )}
      </InnerMain>
    </Main>
  );
};

export default Search;
