import React from 'react';
import Search from '@/components/organisms/SearchByMansionName/Search';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import { Wrapper } from '@/components/layout/common';

type Props = {
  location: {
    state: {
      isFromInformation: boolean;
    };
  };
};

const SearchPage = (props: Props) => {
  const isFromInformation = props.location.state?.isFromInformation;
  return (
    <Wrapper>
      <SellerHeader displayPattern="inquiry" />
      <Search isFromInformation={isFromInformation} />
      <SellerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(SearchPage);
